<template>
    <div class="quick-create-page">
        <div class="quick-create-wrapper">
            <template v-if="!canCreate">
                <div class="engine-page-message flex flex-align-center" style="background-color: #FEF0F0; color: #F56C6C;" v-if="showMessage">
                    <vip-icon :size="22" :type="userInfo.accountType"></vip-icon>
                    <div class="margin-l-10 line-height-24 flex-1">
                        {{ $t('engine.ragCreate_page_alert_1_part1', {type: userInfo.accountTypeDesc, size: userInfo.featureConfig.customGPTEngineCount || 0}) }}<a href="mailto:languagex@besteasy.com" style="text-decoration: underline;">{{$t('engine.ragCreate_page_alert_1_part2')}}</a>
                    </div>
                    <div class="cursor-pointer" @click="showMessage = false"><svg-icon name="ib-close"></svg-icon></div>
                </div>
            </template>
            <template v-else>
                <div class="engine-page-message flex flex-align-center" v-if="showMessage">
                    <svg-icon name="ib-trumpet"></svg-icon>
                    <div class="margin-l-10 line-height-24 flex-1">{{ $t('engine.ragCreate_page_alert_2') }}</div>
                    <div class="cursor-pointer" @click="showMessage = false"><svg-icon name="ib-close"></svg-icon></div>
                </div>
            </template>
            <el-card class="engine-page-card">
                <el-form label-position="top" :disabled="formDisabled">
                    <el-form-item :label="$t('engine.ragCreate_page_form_model_label')" required>
                        <div class="flex flex-align-center">
                            <div class="flex flex-align-end" :disabled="formDisabled">
                                <el-popover placement="bottom-start" trigger="click">
                                    <div class="engine-icon-list">
                                        <template v-for="(icon, index) in config.ENGINE_ICON_LIST">
                                            <div class="item" :key="index" @click="iconItemClick(icon)">
                                                <svg-icon :name="`ie-${icon}`"></svg-icon>
                                            </div>
                                        </template>
                                    </div>
                                    <div slot="reference" class="flex flex-align-center cursor-pointer">
                                        <div class="create-engine-icon">
                                            <svg-icon :name="`ie-${form.icon}`" style="font-size: 20px;"></svg-icon>
                                        </div>
                                        <i class="el-icon-arrow-down fonts-12 margin-l-5"></i>
                                    </div>
                                </el-popover>
                            </div>
                            <el-input v-model="form.name" maxlength="10" show-word-limit style="width:330px;margin-left: 10px;" :placeholder="$t('engine.ragCreate_page_form_model_placeholder')"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item required>
                        <template slot="label">{{ $t('engine.ragCreate_page_form_language_label') }}<span class="form-label-tips"></span></template>
                        <!-- <language-select ref="languageSelect" :list="languageList" :edit="formDisabled"></language-select> -->
                         <div class="flex" style="width: 350px;">
                            <div class="flex-1">
                                <el-select v-model="form.source" @change="langSelectChangeEvent" :placeholder="$t('engine.ragCreate_page_form_language_source_placeholder')" style="width:100%">
                                    <template v-for="item in sourceLanguageList">
                                        <el-option :label="item.name" :value="item.key" :key="item.key"></el-option>
                                    </template>
                                </el-select>
                            </div>
                            <div class="margin-x-10 fonts-20">
                                <svg-icon name="ib-right"></svg-icon>
                            </div>
                            <div class="flex-1">
                                <el-select v-model="form.target" :placeholder="$t('engine.ragCreate_page_form_language_target_placeholder')" style="width:100%">
                                    <template v-for="item in targetLanguageList">
                                        <el-option :label="item.name" :value="item.key" :key="item.key" :disabled="item.key === form.source"></el-option>
                                    </template>
                                </el-select>
                            </div>
                         </div>
                    </el-form-item>
                    <el-form-item>
                        <template slot="label">{{ $t('engine.ragCreate_page_form_term_label') }}<span class="form-label-tips">{{ $t('engine.ragCreate_page_form_term_tips') }}</span></template>
                        <div class="form-terms">
                            <template v-for="(term, index) in form.terms">
                                <div class="flex flex-align-center" :class="{'margin-t-10': index !== 0}" :key="index">
                                    <div class="flex-1"><el-input v-model="term.source" :placeholder="$t('engine.ragCreate_page_form_term_source_placeholder')"></el-input></div>
                                    <div class="flex-1 margin-l-20"><el-input v-model="term.target" :placeholder="$t('engine.ragCreate_page_form_term_target_placeholder')"></el-input></div>
                                    <template v-if="index === form.terms.length - 1">
                                        <div class="fonts-24 margin-l-20 color-999 cursor-pointer" :disabled="formDisabled" @click="addTermItemEvent"><svg-icon name="ib-plus-circle"></svg-icon></div>
                                    </template>
                                    <template v-else>
                                        <div class="fonts-24 margin-l-20 color-999 cursor-pointer" :disabled="formDisabled" @click="form.terms.splice(index, 1)"><svg-icon name="ib-minus-circle"></svg-icon></div>
                                    </template>
                                </div>
                            </template>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <template slot="label">
                            <span class="margin-r-10">{{$t('engine.ragCreate_page_form_demand_label')}}</span>
                            <el-tooltip placement="top">
                                <span class="line-height-1.5" slot="content"><span v-html="$t('engine.ragCreate_page_form_demand_tooltip_content')"></span></span>
                                <span class="color-999"><svg-icon name="ib-question"></svg-icon></span>
                            </el-tooltip>
                        </template>
                        <el-input v-model="form.demand" type="textarea" rows="5" :placeholder="`${$t('engine.ragCreate_page_form_demand_placeholder')}`"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('engine.ragCreate_page_form_description_label')">
                        <el-input v-model="form.descr" :placeholder="$t('engine.ragCreate_page_form_description_placeholder')" maxlength="100" show-word-limit></el-input>
                    </el-form-item>
                    <div class="flex">
                        <div class="flex-1">
                            <el-form-item required style="margin-bottom: 0;">
                                <template slot="label">{{ $t('engine.ragCreate_page_form_memoryFile_label') }}<span class="form-label-tips">{{ $t('engine.ragCreate_page_form_memoryFile_tips') }}</span></template>
                                <upload-files class="create-upload-custom"
                                    ref="memoryFileUpload"
                                    :multiple="true"
                                    title=""
                                    accept=".xlsx,.xls,.tmx"
                                    :edit="formDisabled"
                                    :size="userInfo.featureConfig.singleFileSize"
                                    :limit="50">
                                        <template slot="subtitle">
                                            <div class="color-808080 fonts-12 line-height-1.25" v-html="$t('engine.ragCreate_page_form_memoryFile_message', {size: userInfo.featureConfig.singleFileSize})"></div>
                                        </template>
                                </upload-files>
                            </el-form-item>
                        </div>
                        <div class="flex-1 margin-l-40">
                            <el-form-item style="margin-bottom: 0;">
                                <template slot="label">{{ $t('engine.ragCreate_page_form_termFile_label') }}<span class="form-label-tips">{{ $t('engine.ragCreate_page_form_termFile_tips') }}</span></template>
                                <upload-files class="create-upload-custom"
                                    ref="termFileUpload"
                                    :multiple="true"
                                    title=""
                                    accept=".xlsx,.xls,.tbx"
                                    :edit="formDisabled"
                                    :size="userInfo.featureConfig.singleFileSize"
                                    :limit="50">
                                        <template slot="subtitle">
                                            <div class="color-808080 fonts-12 line-height-1.25" v-html="$t('engine.ragCreate_page_form_termFile_message', {size: userInfo.featureConfig.singleFileSize})"></div>
                                        </template>
                                </upload-files>
                            </el-form-item>
                        </div>
                    </div>
                    
                </el-form>

            </el-card>
            <div class="action-content">
                <el-button type="primary" round :loading="formLoading" :disabled="formDisabled" @click="submitEvent">{{ $t('engine.ragCreate_page_button_submit') }}</el-button>
                <div class="fonts-12 margin-t-10">{{ $t('engine.ragCreate_page_button_submit_message') }}</div>
            </div>
        </div>
        <el-dialog :title="$t('engine.ragCreate_page_success_dialog_title')" :visible.sync="successDialogShow" @closed="$router.go(0)" destroy-on-close width="430px" top="20px">
            <div class="padding-20 text-center">
                <div class="color-success fonts-20"><i class="el-icon-success"></i></div>
                <div class="margin-t-15 fonts-15">{{ $t('engine.ragCreate_page_success_dialog_content_1', {name: form.name}) }}</div>
                <div class="margin-t-10 fonts-12">{{ $t('engine.ragCreate_page_success_dialog_content_2') }}</div>
                <div class="margin-t-10 fonts-12">{{ $t('engine.ragCreate_page_success_dialog_content_3') }}</div>
            </div>
            <div slot="footer" class="text-center">
                <el-button type="primary" size="mini" round @click="$router.push('/engine/config');">{{ $t('engine.ragCreate_page_success_dialog_button') }}</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import config from '@/utils/config';
import LanguageSelect from '@/components/LanguageSelect';
import UploadFiles from '@/components/UploadOss';
import VipIcon from "@/components/icon/VipIcon";
import { mapState } from 'vuex';
import { myEngineApi, toolsApi } from '@/utils/api';
export default {
    components: { LanguageSelect, VipIcon, UploadFiles, },
    data () {
        return {
            config,
            canCreate: true,
            formDisabled: false,
            formLoading: false,
            form: {
                source: 'zh',
                target: 'en',
                name: '',
                icon: '',
                descr: '',
                demand: '',
                terms: [{source:'', target:''}],
            },
            sourceLanguageList: [],
            targetLanguageList: [],
            taskData: {},
            taskTimer: {},
            successDialogShow: false,
            showMessage: true,
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userData,
            activeOrg: state => state.org.activeOrg,
        })
    },
    created () {
        this.initLanguageList();
        this.queryCreatePermission();
        let size = config.ENGINE_ICON_LIST.length;
        this.form.icon = config.ENGINE_ICON_LIST[Math.floor(Math.random()*size)];
    },
    methods: {
        async initLanguageList(){
            let url = `${toolsApi.queryGPTTranslateLanguageOptions}?engine=azure-gpt`;
            const res = await this.$ajax.get(url);
            if(res.status === 200){
                this.sourceLanguageList = res.data.sourceLanguages;
                this.targetLanguageList = res.data.targetLanguages;
            }
        },
        langSelectChangeEvent(val){
            if(val === this.form.target) {
                if(val === 'zh'){
                    this.form.target = 'en';
                }else {
                    this.form.target = 'zh';
                }
            }
        },
        queryCreatePermission(){
            this.$ajax.get(myEngineApi.queryCreateRagPermission).then(res=>{
                if(res.status === 200){
                    this.canCreate = res.data.canCreate;
                    if(!res.data.canCreate){
                        this.formDisabled = true;
                    }
                }
            })
        },
        iconItemClick(icon) {
            this.form.icon = icon;
        },
        
        submitEvent(){
            let self = this;
            // this.lang = this.$refs.languageSelect.getValues();
            if(!this.form.name){
                this.$message.error(this.$t('engine.ragCreate_page_form_verification_message_1'));
                return;
            }
            let memoryFiles = this.$refs.memoryFileUpload.getOssFiles();
            if(memoryFiles.length === 0){
                this.$message.error(this.$t('engine.ragCreate_page_form_verification_message_2'));
                return;
            }
            let termFiles = this.$refs.termFileUpload.getOssFiles();
            
            let url = myEngineApi.createRagEngine;
            let postData = {
                source: this.form.source,
                target: this.form.target,
                engineName: this.form.name,
                descr: this.form.descr,
                icon: this.form.icon,
                requirements: this.form.demand,
                customTerms: this.form.terms.filter(item => item.source && item.target),
                mems: memoryFiles,
                terms: termFiles,
            };
            // console.log(postData);
            // return ;
            this.formDisabled = true;
            this.formLoading = true;
            this.$ajax.post(url, postData).then(res => {
                if(res.status === 200) {
                    this.taskData = res.data;
                    this.queryTaskStatus();
                    this.taskTimer = setInterval(()=>{
                        self.queryTaskStatus();
                    }, 5000);
                }else {
                    this.formDisabled = false;
                    this.formLoading = false;
                }
            }).catch(e => {
                this.formDisabled = false;
                this.formLoading = false;
            })

        },
        queryTaskStatus(){
            let url = `${myEngineApi.getRagTaskStatus}?modelId=${this.taskData.modelId}`;
            this.$ajax.get(url).then(res => {
                if(res.status === 200){
                    if(res.data.status === 'used'){
                        clearInterval(this.taskTimer);
                        this.taskTimer = null;
                        this.formDisabled = false;
                        this.formLoading = false;
                        this.successDialogShow = true;
                        
                    }
                }
            })
        },
        addTermItemEvent(){
            if(this.form.terms.length >= 20){
                this.$message.warning(this.$t('engine.ragCreate_page_form_verification_message_3'));
                return;
            }
            this.form.terms.push({source: '', target: ''})
        },
    },
    mounted() {
        
    },
    beforeDestroy() {
        if(this.taskTimer){
            clearInterval(this.taskTimer);
            this.taskTimer = null;
        }
    },
}
</script>
<style lang="scss" scoped>
$input-border-color: #ccc;
.quick-create-page {
    padding: 12px 0 80px 0;
    background-image: url("../../../static/images/engine/engine-rag-create-bg.png");
    background-position: center top;
    background-repeat: no-repeat;
    background-size: auto;
    .quick-create-wrapper {
        width: 860px;
        margin: auto;
        position: relative;
    }
    ::v-deep .el-input {
        .el-input__inner {
            border-color: $input-border-color;
            &:hover, &:focus {
                border-color: #000000;
            }
        }
        &.is-disabled {
            .el-input__inner {
                background-color: #fff;
                border-color: $input-border-color;
                opacity: .5;
            }
        }

    }
    ::v-deep .el-textarea {
        .el-textarea__inner {
            border-color: $input-border-color;
            &:hover, &:focus {
                border-color: #000000;
            }
        }
        &.is-disabled {
            .el-textarea__inner {
                background-color: #fff;
                border-color: $input-border-color;
                opacity: 0.5;
            }
        }
    }
    
    .create-upload-custom {
        ::v-deep .el-upload-dragger {
            border-color: $input-border-color;
            background-color: #fff;
            &:hover {
                border-color: #000000;
            }
        }
    }
    .engine-page-card{
        margin-top: 12px;
        margin-bottom: 10px;
        border-radius: 10px;
        border: none;
        background-color: #ffffffd1;
        // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
        box-shadow: none;
        ::v-deep .el-card__body{
            padding: 30px;
        }
        ::v-deep .el-form-item__label{
            line-height: 20px;
            font-size: 15px;
            color: #000000;
        }
        ::v-deep .el-input__count{
            line-height: 1;
        }
    }
    .form-terms {
        // max-height: 300px;
        // overflow-y: auto;
    }
    .form-label-tips{
        color: #E98C02;
        font-size: 12px;
        margin-left: 16px;
    }
    .engine-page-message{
        margin-top: 20px;
        font-size: 14px;
        // line-height: 24px;
        // min-height: 44px;
        padding: 10px 20px;
        color: #FF9900;
        background-color: #f7efe0;
        border-radius: 10px;
    }
    .create-engine-icon{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 1px solid #cccccc;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    [disabled]{
        pointer-events: none;
        opacity: 0.4;
    }
    .engine-label-grid{
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fill,minmax(100px,1fr));
        line-height: 1;
    }
    .style-grid{
        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(auto-fill,minmax(72px,1fr));
        .item {
            height: 36px;
            background-color: #F8F8F8;
            border: 1px solid transparent;
            border-radius: 4px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &.active {
                border-color: #000000;
            }
        }
    }
}
.doc-textarea{
    ::v-deep .el-textarea__inner{
        border-color: #7DD6B3;
    }
}
.engine-icon-list{
    display: flex;
    .item {
        font-size: 24px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        &:first-child{
            margin-left: 0;
        }
        &:hover{
            background-color: #f2f2f2;
        }
    }
}
.action-content{
    position: fixed;
    text-align: center;
    padding:10px 0;
    background-color:#f8f8f8;
    bottom: 0;
    width: 860px;
}
</style>
