<!-- 
    语言方向选择组件，两个下拉列表实现
 -->
<template>
    <div class="language-select-component">
        <div class="source-select">
            <el-select v-model="form.sourceLang" :disabled="edit" @change="sourceLangChange" style="width:120px;">
                <el-option v-for="value in list" :key="value.key" :value="value.key" :label="value.name"></el-option>
            </el-select>
        </div>
        <div class="transition-icon" :disabled="edit" @click="exchangeLanguage">
            <el-tooltip :content="$t('component.LanguageSelect_button_exchang')" placement="top">
                <svg-icon name="ib-transition"></svg-icon>
            </el-tooltip>
        </div>
        <div class="target-select">
            <el-select v-model="form.targetLang" :disabled="edit" style="width:120px;">
                <el-option 
                    v-for="value in list" 
                    :key="value.key" 
                    :value="value.key" 
                    :label="value.name" :disabled="value.key === form.sourceLang"></el-option>
            </el-select>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        list:{
            type:Array,
            default:[]
        },
        edit:{
            type: Boolean,
            default: false,
        }
    },
    data(){
        return {
            form: {
                sourceLang: 'zh',
                targetLang: 'en',
            }
        }
    },
    methods:{
        sourceLangChange(val){
            if(val === this.form.targetLang){
                if(val === 'zh'){
                    this.form.targetLang = 'en';
                }else{
                    this.form.targetLang = 'zh';
                }
            }
        },
        exchangeLanguage(){
            let tmpSourceValue = this.form.sourceLang;
            let tmpTargetValue = this.form.targetLang;
            this.form.sourceLang = tmpTargetValue;
            this.form.targetLang = tmpSourceValue;
        },
        getValues(){
            return this.form;
        },
        setFormValues(value){
            // console.log('1111:::===',value);
            this.form = value;
        }
        
    }
}
</script>
<style lang="scss" scoped>
.language-select-component{
    display: flex;
    align-items: center;
    .transition-icon{
        margin: 0 12px;
        cursor: pointer;
        text-align: center;
        svg{
            font-size: 18px;
        }
        &[disabled],&:disabled{
            cursor: not-allowed;
            pointer-events: none;
            opacity: .5;
        }
    }
}
</style>
